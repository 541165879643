import styled from "styled-components";

export const Wrapper = styled.div`
  .MuiBox-root{
    padding: 0;
  }

  .tab-panels {
    min-height: 70vh;
  }
`
